<template>
  <div class="mt-2">
    <template v-if="currentItem">
      <b-row class="mb-1" align-h="center">
        <b-col lg="4" md="5" class="d-flex align-items-center">
          <b-button
            @click="currentItem = null"
            variant="outline-secondary"
            class="btn-icon rounded-circle mr-50"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="48"
                :src="currentItem.picture"
                variant="light-primary"
              />
            </template>

            <b-badge variant="light-primary" class="mb-25">
              {{ currentItem.name }}
            </b-badge>

            <span class="font-weight-bold d-block text-nowrap mb-25">
              <b-badge variant="light-primary">
                {{ currentItem.contact_number }}
              </b-badge>
            </span>
          </b-media>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col lg="4" md="5">
          <h4 class="mb-1">Donation History</h4>
          <b-card>
            <app-timeline>
              <app-timeline-item
                variant="primary"
                v-for="(item, ind) in details"
                :key="ind"
              >
                <div class="mb-1 mb-sm-0">
                  <p class="mb-25">
                    <b-badge variant="success">Rs.{{ item.amount }}</b-badge>
                  </p>
                  <p class="font-weight-bold mb-0">
                    {{
                      new Date(item.date).toLocaleDateString("en-UK", {
                        year: "numeric",
                        day: "numeric",
                        month: "short",
                      })
                    }}
                  </p>
                </div>
              </app-timeline-item>
            </app-timeline>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row class="hover-card">
        <b-col md="4" sm="6">
          <b-card bg-variant="success" text-variant="white" class="">
            <h4 class="text-white font-weight-bolder">Total Donors</h4>
            <p
              class="font-weight-bolder mb-0 font-large-1 line-height-1 d-flex align-items-center justify-content-between"
            >
              <feather-icon icon="UserCheckIcon" size="26" />
              154
            </p>
          </b-card>
        </b-col>
        <b-col md="4" sm="6">
          <b-card bg-variant="warning" text-variant="white" class="">
            <h4 class="text-white font-weight-bolder">New Donors</h4>
            <p
              class="font-weight-bolder mb-0 font-large-1 line-height-1 d-flex align-items-center justify-content-between"
            >
              <feather-icon icon="UsersIcon" size="26" />
              34
            </p>
          </b-card>
        </b-col>
        <b-col md="4" sm="6">
          <b-card bg-variant="info" text-variant="white" class="">
            <h4 class="text-white font-weight-bolder">Total Donations</h4>
            <p
              class="font-weight-bolder mb-0 font-large-1 line-height-1 d-flex align-items-center justify-content-between"
            >
              <feather-icon icon="TrendingUpIcon" size="26" />
              Rs. {{ (675000).toLocaleString() }}
            </p>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-row v-if="false" class="px-1 py-2">
          <b-col md="3">
            <b-button variant="primary" class="" block>
              <feather-icon icon="PlusIcon" />
              Add Donor
            </b-button>
          </b-col>
          <b-col class="d-flex align-items-center" md="9">
            <b-form-group class="mb-0 w-100">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input v-model="searchQuery" placeholder="Search...">
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          class=""
          :items="items"
          :fields="fields"
          :busy="dataLoading"
          show-empty
          responsive
          hover
          small
        >
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>

          <template #cell(person)="data">
            <b-media vertical-align="center" class="align-items-center">
              <template #aside>
                <b-avatar
                  size="48"
                  :src="data.item.picture"
                  variant="light-primary"
                />
              </template>

              <b-badge variant="light-primary">
                {{ data.item.name }}
              </b-badge>

              <!-- <span class="font-weight-bold d-block text-nowrap mt-25">
                <b-badge variant="light-primary">
                  F/N: {{ data.item.father_name }}
                </b-badge>
              </span> -->
            </b-media>
          </template>

          <template #cell(joiningDate)="data">
            <b-badge variant="light-primary">
              {{
                new Date(data.item.joiningDate).toLocaleDateString("en-UK", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              }}
            </b-badge>
          </template>

          <template #cell(contact)="data">
            <b-badge variant="light-primary">
              {{ data.item.contact_number }}
            </b-badge>
          </template>
          <template #cell(total)="data">
            <b-badge variant="light-primary">
              Rs.{{ data.item.total.toLocaleString() }}
            </b-badge>
          </template>
          <template #cell(students)="data">
            <b-badge variant="light-primary">
              {{ data.item.students }}
            </b-badge>
          </template>

          <!-- <template #head(actions)="data">
              <div class="ml-2">
                <span>{{ data.label }}</span>
              </div>
            </template> -->

          <template #cell(actions)="data">
            <div class="">
              <b-button
                variant="primary"
                v-b-tooltip.hover.left
                title="View History"
                class="btn-icon"
                @click="openDetails(data.item)"
              >
                <feather-icon icon="EyeIcon" class="" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </template>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BFormSpinbutton,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BFormSpinbutton,
    BAvatar,
    vSelect,
    flatPickr,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      dataLoading: false,
      fields: [
        { label: "person", key: "person" },
        { label: "contact", key: "contact" },
        { label: "joined at", key: "joiningDate" },
        { label: "total donation", key: "total" },
        { label: "no.of students", key: "students" },
        { key: "actions", label: "actions" },
      ],
      items: [
        {
          name: "M. Athar",
          father_name: "Akbar",
          picture: "https://cdn.cloudious.net/file-1690179734393-436589437.png",
          joiningDate: "2024-02-01T00:00:00",
          contact_number: "+923022069289",
          total: 155000,
          students: 25,
        },
        {
          name: "Junaid",
          father_name: "Alam Khan",
          picture:
            "https://cdn.cloudious.net/compressed_file-1719401659237-660544691.jpg",
          joiningDate: "2024-06-05T00:00:00",
          contact_number: "+923211220608",
          total: 349900,
          students: 52,
        },
        {
          name: "Nasir Farooqui",
          father_name: "Farooq Ahmad",
          picture:
            "https://cdn.cloudious.net/compressed_file-1727087652673-286935839.jpg",
          joiningDate: "2024-04-23T00:00:00",
          contact_number: "+923000250101",
          total: 243080,
          students: 33,
        },
        {
          name: "Muhammad Adil",
          father_name: "Muhammad Ashraf",
          picture: "https://cdn.cloudious.net/file-1710149601154-987223442.png",
          joiningDate: "2024-05-17T00:00:00",
          contact_number: "+923208111821",
          total: 78450,
          students: 21,
        },
        {
          name: "Maryam ",
          father_name: "Ali",
          picture: "https://cdn.cloudious.net/file-1710144567025-373275648.jpg",
          joiningDate: "2024-05-05T00:00:00",
          contact_number: "+923239008909",
          total: 246800,
          students: 39,
        },
      ],
      currentItem: null,
      details: [
        { amount: 2300, date: "2024-10-25" },
        { amount: 3500, date: "2024-10-18" },
        { amount: 4000, date: "2024-09-09" },
        { amount: 2500, date: "2024-08-13" },
      ],
    };
  },
  methods: {
    openDetails(item) {
      this.currentItem = { ...item };
    },
  },
};
</script>
<style scoped></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
